import React from "react";

const Jobs = () => {
  return (
    <section
      id="experience"
      className="py-16 flex items-center justify-center relative overflow-hidden"
    >
      <div className="absolute top-0 left-0 w-full h-full z-10"></div>

      <div className="container mx-auto px-4 flex flex-col">
        <div
          style={{
            background: "linear-gradient(45deg, black, white)",
            padding: "10px",
          }}
        >
          <div className="p-8 bg-white rounded-lg shadow-lg relative">
            <h2 className="text-3xl font-bold mb-8 text-gray-900 text-center">
              Professional Experience
            </h2>
            <hr className="mb-8" />
            <div className="space-y-12 spacebelow" >

              <div className="p-8 bg-white rounded-lg shadow-lg relative overflow-hidden">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-gray-300 to-gray-100 opacity-25"
                  style={{ pointerEvents: "none" }}
                ></div>
                <div className="md:flex md:space-x-8 relative z-10">
                  <div className="md:w-1/3 mb-6 md:mb-0 flex items-center justify-center shadow-lg">
                    <img
                      src="/images/caring connections.png"
                      alt="Caring Connections Logo"
                      className="object-contain mx-auto"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />
                  </div>

                  <div className="md:w-2/3">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">
                      Web Developer - Freelancer - Caring Connections.
                    </h3>
                    <p className="text-gray-700 mb-4">
                      (Jan 2023 to present)
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-gray-700">
                      <li>
                        Built the platform using WordPress, integrating Elementor for advanced page-building functionality.                      
                        </li>
                      <li>
                        Integrated custom interactive forms and dynamic elements using PHP and JavaScript to enhance user engagement.                      
                      </li>
                      <li>
                        Optimized the user interface with Font Awesome icons, improving visual appeal and usability.
                      </li>
                      <li>
                        Ensured seamless performance by leveraging Google Tag Manager for analytics and tracking.                      
                      </li>
                    <li>Customized the website with jQuery and CSS for responsive design and enhanced user experience.</li>
                    <li>Technologies used: PHP, HTML, CSS, JavaScript, jQuery, Bootstrap, WordPress, Elementor.</li>
                    <li>Website Link: caringconnections.us</li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
                        <div className="space-y-12 spacebelow" >
              <div className="p-8 bg-white rounded-lg shadow-lg relative overflow-hidden">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-gray-300 to-gray-100 opacity-25"
                  style={{ pointerEvents: "none" }}
                ></div>
                <div className="md:flex md:space-x-8 relative z-10">
                  <div className="md:w-1/3 mb-6 md:mb-0 flex items-center justify-center shadow-lg">
                    <img
                      src="/images/tandon gems.jpeg"
                      alt="Tandon Gems Logo"
                      className="object-contain mx-auto"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />
                  </div>
                  <div className="md:w-2/3">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">
                      Web Developer - Freelancer - Caring Connections.
                    </h3>
                    <p className="text-gray-700 mb-4">
                      (May 2022 to December 2022)
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-gray-700">
                      <li>Designed and developed the platform from scratch using WordPress, ensuring a user-friendly interface and seamless navigation.</li>
                      <li>Built advanced interactive forms and dynamic features with PHP and JavaScript to enhance functionality and improve customer engagement.</li>
                      <li>Crafted a fully responsive layout using Bootstrap and custom CSS, delivering a consistent user experience across all devices and browsers.</li>
                      <li>Integrated Google Analytics and Google Tag Manager to monitor performance, track user behavior, and optimize website usability.</li>
                      <li>Enhanced the platform’s visual appeal by incorporating jQuery animations, Font Awesome icons, and modern UI/UX principles.</li>
                      <li>Technologies used: PHP, HTML, CSS, JavaScript, jQuery, Bootstrap, WordPress.</li>
                      <li>Website Link: tandongems.com</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-12 spacebelow">
              {/* IQUANTI Inc. */}
              <div className="p-8 bg-white rounded-lg shadow-lg relative overflow-hidden">
                <div
                  className="absolute inset-0 bg-gradient-to-r from-gray-300 to-gray-100 opacity-25"
                  style={{ pointerEvents: "none" }}
                ></div>
                <div className="md:flex md:space-x-8 relative z-10">
                  <div className="md:w-1/3 mb-6 md:mb-0 flex items-center justify-center shadow-lg">
                    <img
                      src="/images/iquanti.png"
                      alt="IQUANTI Logo"
                      className="object-contain mx-auto"
                      style={{ marginBottom: "10px", marginTop: "10px" }}
                    />
                  </div>

                  <div className="md:w-2/3">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">
                      Web Developer Intern - IQUANTI Inc.
                    </h3>
                    <p className="text-gray-700 mb-4">
                      <b>Projects:</b> American Express Global Consumer
                      Services, LAUREL ROAD, DIGIBANK by DBS, Global Hospitals,
                      ALPS new build (Jan 2021 to Jan 2022)
                    </p>
                    <ul className="list-disc list-inside space-y-2 text-gray-700">
                      <li>
                      Collaborated on projects for esteemed clients like American Express Insurance, LAUREL ROAD, DIGIBANK by DBS, Global Hospitals, and ALPS, focusing on web development using WordPress and frameworks such as PHP, MySQL, and JavaScript.
                      </li>
                      <li>
                        Spearheaded WordPress multisite infrastructure maintenance for the American Express client, ensuring project milestones and deadlines were met while optimizing web page performance and managing content migration.
                      </li>
                      <li>
                        Led development efforts for multiple landing pages in the LAUREL ROAD and Global Hospitals projects, while also contributing significantly to the IQUANTI ALPS projects strategy, execution, and design quality, showcasing expertise in UI/UX design, theme creation, and content management across various web platforms.
                      </li>
                      <li>
                        Ensured compliance with WCAG standards across web platforms to enhance accessibility for users with disabilities, contributing to the optimization of web page performance and user experience.
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>

              {/* Client Logos Carousel */}
              <div className="bg-white py-4 mt-8 rounded-lg shadow-md">
                <div className="container mx-auto px-4">
                  <h4 className="text-lg font-semibold text-center mb-4">
                    Clients Worked With
                  </h4>
                  <div className="overflow-hidden relative">
                    <div className="scroll-container">
                      <div className="scroll-content">
                        <img
                          src="/images/amex.png"
                          alt="American Express Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/laurelroad.png"
                          alt="Laurel Road Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/DBS.png"
                          alt="DBS Bank Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/gh.png"
                          alt="Global Hospitals Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/ALPS.png"
                          alt="ALPS Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                      </div>
                      <div className="scroll-content">
                        <img
                          src="/images/amex.png"
                          alt="American Express Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/laurelroad.png"
                          alt="Laurel Road Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/DBS.png"
                          alt="DBS Bank Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/gh.png"
                          alt="Global Hospitals Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                        <img
                          src="/images/ALPS.png"
                          alt="ALPS Logo"
                          className="h-20 w-28 md:h-24 md:w-32 lg:h-30 lg:w-40 mx-3 object-contain"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .scroll-container {
          display: flex;
          width: calc(200%); /* Double the width to hold two sets of content */
          animation: scroll 20s linear infinite;
        }
        .spacebelow{
        margin-bottom:2%;
        }

        .scroll-content {
          display: flex;
          width: 50%; /* Each content set takes up half of the container */
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        /* Adjust animation speed and spacing for different screen sizes */
        @media (max-width: 768px) {
          .scroll-container {
            animation: scroll 30s linear infinite;
          }
          .scroll-content img {
            width: 11%;
            height: 100%;
          }
        }

        @media (min-width: 768px) {
          .scroll-container {
            animation: scroll 25s linear infinite;
          }
          .scroll-content img {
            width: 100px;
            height: 80px;
          }
        }

        @media (min-width: 1024px) {
          .scroll-content img {
            width: 18%;
            height: 60%;
          }
        }
      `}</style>
    </section>
  );
};

export default Jobs;
